import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, } from '@mui/material';
// components
import { useSnackbar } from 'notistack';
import Iconify from '../../../../components/iconify';
import { Upload } from '../../../../components/upload';
import { insertFiles } from '../../../../redux/slices/prothese/protheseSlice';
import { dispatch } from '../../../../redux/store';
export default function UploadMultiple({ title = 'Upload Files', open, onClose, 
//
onCreate, onUpdate, folderName, onChangeFolderName, currentFiles, ...other }) {
    const [files, setFiles] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (!open) {
            setFiles([]);
        }
    }, [open]);
    const handleDrop = useCallback((acceptedFiles) => {
        const newFiles = acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file),
        }));
        setFiles([...files, ...newFiles]);
    }, [files]);
    const handleUpload = () => {
        if (currentFiles?.length < 10 && currentFiles.length + files.length < 10) {
            dispatch(insertFiles([...currentFiles, ...files]));
            onClose();
        }
        else {
            enqueueSnackbar('Vous ne pouvez pas télécharger plus de 10 fichiers', {
                variant: 'error',
            });
            onClose();
        }
    };
    const handleRemoveFile = (inputFile) => {
        const filtered = files.filter((file) => file !== inputFile);
        setFiles(filtered);
    };
    const handleRemoveAllFiles = () => {
        setFiles([]);
    };
    return (_jsxs(Dialog, { fullWidth: true, maxWidth: "sm", open: open, onClose: onClose, ...other, children: [_jsxs(DialogTitle, { sx: { p: (theme) => theme.spacing(3, 3, 2, 3) }, children: [" ", title, " "] }), _jsxs(DialogContent, { dividers: true, sx: { pt: 1, pb: 0, border: 'none' }, children: [(onCreate || onUpdate) && (_jsx(TextField, { fullWidth: true, label: "Folder name", value: folderName, onChange: onChangeFolderName, sx: { mb: 3 } })), _jsx(Upload, { multiple: true, files: files, onDrop: handleDrop, onRemove: handleRemoveFile })] }), _jsxs(DialogActions, { children: [_jsx(Button, { variant: "contained", startIcon: _jsx(Iconify, { icon: "eva:cloud-upload-fill" }), onClick: handleUpload, children: "Add" }), !!files.length && (_jsx(Button, { variant: "outlined", color: "inherit", onClick: handleRemoveAllFiles, children: "Remove all" })), (onCreate || onUpdate) && (_jsx(Stack, { direction: "row", justifyContent: "flex-end", flexGrow: 1, children: _jsx(Button, { variant: "soft", onClick: onCreate || onUpdate, children: onUpdate ? 'Save' : 'Create' }) }))] })] }));
}
